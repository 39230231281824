import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'

const StyledDiv = styled.div`
  .text-shadow-blue {
    text-shadow: 0 3px 3px #003057;
  }
`

const StyledUnderline = styled.div`
  width: 80px;
  height: 4px;
  background: #A6192E;
`

const PageAccessibilitySection = () => (
  <>
    <div className="relative" style={{ display: "grid", background: "#423E42" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 300,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Terms Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={1920}
        className="hidden md:block"
      />

      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
          objectFit: "cover",
          opacity: "0.4",
          height: 200,
        }}
        // This is a presentational image, so the alt should be an empty string
        alt="Terms Page Hero Background"
        src={
          "../../images/backgrounds/home-hero-bg-1.jpg"
        }
        formats={["auto", "webp", "avif"]}
        width={800}
        className="block md:hidden"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
          <StyledDiv className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
            <h2 className='text-4xl lg:text-5xl font-medium text-white text-center text-shadow-blue uppercase mt-6'>
              <span className='leading-tight'>Accessibility</span>
            </h2>
          </StyledDiv>
        </div>
      </div>
    </div>

    <div className="w-full bg-gray-100">
      <div className='relative w-11/12 max-w-screen-xl bg-gray-100 py-14 md:py-20 lg:py-24 lg:w-10/12 mx-auto'>
        <div className='w-full md:w-9/12 mx-auto'>
          <h2 className='font-display text-site-blue text-4xl font-bold mb-6 leading-tight'>Tiarna Real Estate Services Accessibility Statement</h2>

          <StyledUnderline className='mb-6'></StyledUnderline>
        </div>

        <div className='w-full md:w-9/12 mx-auto'>

          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>General</h3>
          <p className='text-xl text-gray-900 mb-6'>Tiarna Real Estate Services strives to ensure that its services are accessible to people with disabilities. Tiarna Real Estate Services has invested a significant amount of resources to help ensure that its website is made easier to use and more accessible for people with disabilities, with the strong belief that every person has the right to live with dignity, equality, comfort and independence.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Accessibility on Tiarna Real Estate Services</h3>
          <p className='text-xl text-gray-900 mb-6'>Tiarna Real Estate Services makes available the <a href='https://userway.org/' target='_blank' rel='noopener noreferrer' className='underline font-bold'>UserWay Website Accessibility Widget</a> that is powered by a dedicated accessibility server. The software allows Tiarna Real Estate Services to improve its compliance with the Web Content Accessibility Guidelines (WCAG 2.1).</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Enabling the Accessibility Menu</h3>
          <p className='text-xl text-gray-900 mb-6'>Tiarna Real Estate Services accessibility menu can be enabled by clicking the accessibility menu icon that appears on the bottom right corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Disclaimer</h3>
          <p className='text-xl text-gray-900 mb-3'>Tiarna Real Estate Services continues its efforts to constantly improve the accessibility of its site and services in the belief that it is our collective moral obligation to allow seamless, accessible and unhindered use also for those of us with disabilities.</p>
          <p className='text-xl text-gray-900 mb-6'>Despite our efforts to make all pages and content on Tiarna Real Estate Services fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Here For You</h3>
          <p className='text-xl text-gray-900 mb-6'>If you are experiencing difficulty with any content on tiarna.com or require assistance with any part of our site, please contact us during normal business hours as detailed below and we will be happy to assist.</p>
          <h3 className='font-display text-gray-900 text-2xl mb-2 font-bold'>Contact Us</h3>
          <p className='text-xl text-gray-900 mb-6'>If you wish to report an accessibility issue, have any questions or need assistance, please <Link to="/contact" className='underline font-bold'>click here</Link> to contact us.</p>
        </div>
      </div>
    </div>
  </>
)

export default PageAccessibilitySection