import React from "react"

import LayoutPage from "../components/layout/layout-page"
import PageAccessibilitySection from "../components/sections/page-accessibility-section"
import Seo from "../components/seo"

const AccessibilityPage = () => (
  <LayoutPage>
    <Seo title="Accessibility Statement" />

    <PageAccessibilitySection />
  </LayoutPage>
)

export default AccessibilityPage
